import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Home from './screens/home';
import Contact from './screens/contact';
import NotFound from './screens/notFound';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/faleconosco" exact component={Contact} />
      <Route path="/404" exact component={NotFound} />
      <Route render={() => <Redirect to="/404" />} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
