import Routes from './routes';
import { AppHeader } from './components/header'
import { AppFooter } from './components/footer'

const App = () => (
  <div className="App">
    <AppHeader />
    <Routes />
    <AppFooter />
  </div>
);

export default App;
