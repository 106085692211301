import React from 'react';
import { Layout } from 'antd';
const { Content } = Layout;
import {
  Container,
  Banner,
  BannerContainer,
  ContactContainer,
  Legend,
  Description,
  LegendEmail
} from './styles';

const Contact: React.FC = () => {
  return (
    <Layout>
      <BannerContainer bgColor={"linear-gradient(to right, #000 0%, #737373 100%)"}>
        <Banner src={require('../../assets/images/FaleConosco.png')} alt="FaleConosco" />
      </BannerContainer>
      <Content style={{ padding: '0 4px', flex: 1 }}>
        <Container>
          <ContactContainer>
            <Legend>Entre em Contato!</Legend>
            <Description>Estamos sempre abertos para ouvir você! Queremos que nossa plataforma de palpites de futebol seja a melhor possível e, para isso, precisamos da sua opinião. Seja você um usuário fiel ou alguém que acabou de nos conhecer, suas sugestões e comentários são extremamente valiosos.</Description>
          </ContactContainer>
          <ContactContainer>
            <Legend>Divulgações e Parcerias</Legend>
            <Description>Representa uma empresa interessada em se associar a nós? Estamos empolgados em explorar novas oportunidades de parceria que tragam benefícios mútuos. Colaborando juntos, podemos alcançar novos públicos e proporcionar uma experiência ainda mais rica para nossos usuários. Entre em contato conosco para discutirmos como podemos trabalhar juntos em campanhas de divulgação, ações promocionais e muito mais.</Description>
          </ContactContainer>
          <ContactContainer>
            <Legend>Você pode nos contatar através do email:</Legend>
            <LegendEmail>contato@palpitefutebol.com</LegendEmail>
          </ContactContainer>
        </Container>
      </Content>
    </Layout>
  );
};

export default Contact;
