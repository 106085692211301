import styled from 'styled-components';
import { HomeFilled } from '@ant-design/icons';

export const Legend = styled.div`
  font-size: 14px;
  font-family: 'Calibri', sans-serif;
  font-weight: 600;
  color: #1c1c1c;
  @media (max-width: 768px) {
    font-size: 12px;
  }
  @media (max-width: 576px) {
    font-size: 10px;
  }
`;

export const TeamLegend = styled.div`
  font-size: 18px;
  font-family: 'Calibri', sans-serif;
  font-weight: 500;
  color: #1c1c1c;
  margin-left: 8px;
  margin-right: 8px;
  @media (max-width: 768px) {
    font-size: 14px;
    margin-left: 6px;
    margin-right: 6px;
  }
  @media (max-width: 576px) {
    font-size: 12px;
    margin-left: 4px;
    margin-right: 4px;
  }
`;

export const RoundContainer = styled.div`
  display: flex;
  background: #363636;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  flex-direction: column;
  font-family: 'Calibri', sans-serif;
  @media (max-width: 768px) {
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 12px;
  }
`;

export const RoundTitle = styled.div`
  font-size: 18px;
  font-family: 'Google Sans', arial, sans-serif;
  font-weight: 400;
  color: #fff;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const RoundLegend = styled.div`
  font-size: 16px;
  font-family: 'Calibri', sans-serif;
  font-weight: 300;
  color: #fff;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const TeamContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const StyledHomeOutlined = styled(HomeFilled)`
  font-size: 18px;
  color: #9C9C9C;
  align-items: center;
  justify-content: center;
  @media (max-width: 992px) {
    font-size: 16px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

export const HomeInfo = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
`;

export const AwayInfo = styled.div`
  display: flex;
  flex-direction: reverse;
  align-items: center;
  width: 100%;
`;

interface VersusMargin {
  Margin: number;
  MarginAux: boolean;
}

export const VersusContainer = styled.div<VersusMargin>`
  display: flex;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: ${({ Margin }) => Margin + 'px'};
  margin-right: ${({ MarginAux }) => (MarginAux ? '8px' : '0')};
  margin-left: ${({ MarginAux }) => (MarginAux ? '8px' : '0')};
  @media (max-width: 992px) {
    margin-top: ${({ Margin }) => (Margin - 11) + 'px'};
    padding-left: 8px;
    padding-right: 8px;
  }
  @media (max-width: 768px) {
    margin-top: ${({ Margin }) => (Margin - 12) + 'px'};
    padding-left: 8px;
    padding-right: 8px;
  }
  @media (max-width: 576px) {
    margin-top: ${({ Margin }) => (Margin - 14) + 'px'};
    padding-left: 4px;
    padding-right: 4px;
  } 
`;

export const Versus = styled.div`
  color: #B5B5B5;
  font-size: 18px;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 14px;
    font-weight: 400;
  }
  @media (max-width: 576px) {
    font-size: 12px;
    font-weight: 400;
  } 
`;

export const TeamImage = styled.img`
  height: 65px;
  width: 65px;
  @media (max-width: 992px) {
    height: 45px;
    width: 45px;
  }
  @media (max-width: 768px) {
    height: 35px;
    width: 35px;
  }
  @media (max-width: 576px) {
    height: 25px;
    width: 25px;
  }
`;

interface GuessTitleProps {
  status: 'pending' | 'correct' | 'incorrect';
}

export const GuessTitle = styled.div<GuessTitleProps>`
  background: ${({ status }) => {
    if (status === 'correct') return '#008b00';
    if (status === 'incorrect') return '#ee2c2c';
    return '#EEAD0E';
  }};
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 2px;
  font-size: 14px;
  font-family: 'Calibri', sans-serif;
  font-weight: 500;
  margin: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 12px;
    font-weight: 500;
    padding: 2px;
  }
  @media (max-width: 576px) {
    font-size: 10px;
    font-weight: 400;
  }
`;

export const GuessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 200px;
  margin-top: 16px;
  @media (max-width: 768px) {
    margin-top: 8px;
    width: 150px;
  }
  @media (max-width: 576px) {
    margin-top: 4px;
    width: 100px;
  }
`;

export const MatchContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 8px;
  @media (max-width: 768px) {
    margin: 2px;
  }
`;

export const MatchInfo = styled.div`
  font-size: 16px;
  font-family: 'Calibri', sans-serif;
  font-weight: 800;
  color: #2d2d2d;
  margin: 4px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

export const MatchColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const MatchColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
`;

export const MatchLegend = styled.div`
  font-size: 16px;
  font-family: 'Calibri', sans-serif;
  font-weight: 400;
  color: #1c1c1c;
  margin-bottom: 2px;
  margin-left: 4px;
  @media (max-width: 768px) {
    font-size: 14px;
    margin-left: 2px;
  }
  @media (max-width: 576px) {
    font-size: 12px;
    margin-left: 2px;
  }
`;

interface MatchStatusProps {
  status: 'agendado' | 'finalizado' | 'adiado';
}

export const MatchStatus = styled.div<MatchStatusProps>`
  font-size: 16px;
  font-family: 'Calibri', sans-serif;
  font-weight: 400;
  margin-left: 4px;
  color: ${({ status }) => {
    if (status === 'finalizado') return '#B5B5B5';
    if (status === 'adiado') return '#1874CD';
    return '#ee9a00';
  }};
  @media (max-width: 768px) {
    font-size: 14px;
    margin-left: 2px;
  }
  @media (max-width: 576px) {
    font-size: 12px;
    margin-left: 2px;
  }
`;

export const ResultScore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-family: 'Calibri', sans-serif;
  font-weight: bold;
  text-align: center;
  color: #2d2d2d;
  margin-right: 16px;
  margin-left: 16px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
  @media (max-width: 576px) {
    font-size: 18px;
    margin-right: 8px;
    margin-left: 8px;
  }
`;

export const ResultImage = styled.img`
  height: 50px;
  width: 50px;
  @media (max-width: 992px) {
    height: 35px;
    width: 35px;
  }
  @media (max-width: 768px) {
    height: 25px;
    width: 25px;
  }
  @media (max-width: 576px) {
    height: 20px;
    width: 20px;
  }
`;

export const DescriptionContainer = styled.div`
  display: 'flex';
  width: 100%;
  justify-content: center;
`;

export const Description = styled.div`
  font-size: 14px;
  font-family: 'Calibri', sans-serif;
  font-weight: 400;
  color: #696969;
  text-align: justify;
  margin-top: 8px;
  margin-left: 4px;
  @media (max-width: 992px) {
    font-size: 12px;
    margin-top: 2px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    margin-top: 2px;
  }
  @media (max-width: 576px) {
    font-size: 10px;
    margin-top: 1px;
  }
`;
