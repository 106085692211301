import { Layout } from 'antd';

const { Footer } = Layout;

export function AppFooter() {
  return (
    <Footer style={{ textAlign: 'center', fontSize: 12, color: '#1c1c1c' }}>
      www.palpitefutebol.com - {new Date().getFullYear()} | Created by @lukeguima
    </Footer>
  )
}