import styled from 'styled-components';

export const Logo = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 120px;
  max-height: 40px;
  width: auto;
  height: auto;
  margin-left: -44px;
  @media (max-width: 768px) {
    margin-left: -44px;
    max-width: 100px;
    max-height: 40px;
  }
`;

export const Button = styled.a`
  font-size: 16px;
  font-weight: 700;
  color: red;
  @media (max-width: 992px) {
    font-size: 14px;
    font-weight: 700;
  }
  @media (max-width: 576px) {
    font-size: 12px;
    font-weight: 700;
  }
`;