import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
const { Header } = Layout;
import type { MenuProps } from 'antd';
import {
  Logo,
  Button
} from './styles';
import logo from '../../assets/logo.png';

const items: MenuProps['items'] = [
  {
    key: 1,
    label: (
      <Button href="/">
        palpites
      </Button>
    ),
  },
  {
    key: 2,
    label: (
      <Button href="/faleconosco">
        fale conosco
      </Button>
    ),
  }
]

export function AppHeader() {
  const [current, setCurrent] = useState('/');

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
  };

  return (
    <Header
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        background: '#FFF'
      }}
    >
      <Logo src={logo} />
      <Menu
        style={{ flex: 1, minWidth: 0, marginLeft: 8 }}
        mode="horizontal"
        onClick={onClick}
        selectedKeys={[current]}
        defaultSelectedKeys={['1']}
        items={items}
      />
    </Header>
  )
}
