import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Layout, Carousel } from 'antd';
const { Content } = Layout;
import { baseUrl } from '../../environments/config';
import { DataItem } from '../home/model';
import GuessList from '../../components/GuessList';
import {
  GridContent,
  Banner,
  BannerContainer,
  AdsContainer,
  Ads,
  ErrorContainer,
  Error,
  RefreshButton,
  LoadingSpinner
} from './styles';

const Home: React.FC = () => {
  const [data, setData] = useState<DataItem | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const currentUrl = baseUrl + 'brasileiraoSerieAGuess';
      try {
        const response = await axios.get<DataItem>(currentUrl);
        setData(response.data);
        setError(null); // Limpa o erro ao obter sucesso
      } catch (error) {
        setError(error as Error); // Type assertion
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [forceRefresh]);

  const handleRefresh = () => {
    setForceRefresh(prev => !prev);
  };

  const Guess: React.FC<{ data: DataItem | null; error: Error | null }> = ({ data, error }) => {
    if (loading) {
      return (
        <ErrorContainer>
          <LoadingSpinner />
        </ErrorContainer>
      );
    }

    if (error) {
      return (
        <ErrorContainer>
          <Error src={require('../../assets/images/ErrorNetworking.png')} alt="Error Networking" />
          <RefreshButton onClick={handleRefresh}>recarregar página</RefreshButton>
        </ErrorContainer>
      );
    }

    if (!data) {
      return (
        <div>Carregando...</div>
      );
    }

    return (
      <div style={{ width: '100%' }}>
        <GuessList palpites={data.palpites} rodada={data.rodada} status={data.status} />
      </div>
    );
  };

  return (
    <Layout>
      <Carousel autoplay style={{ padding: '0 4px' }}>
        <BannerContainer bgColor={"linear-gradient(to right, #0097b2 0%, #7ed957 100%)"}>
          <Banner src={require('../../assets/images/Banner1.png')} alt="Banner1" />
        </BannerContainer>
        <BannerContainer bgColor={"linear-gradient(to right, #ffde59  0%, #ff914d 100%)"}>
          <Banner src={require('../../assets/images/Banner2.png')} alt="Banner2" />
        </BannerContainer>
        <BannerContainer bgColor={"linear-gradient(to right, #5de0e6 0%, #004aad 100%)"}>
          <Banner src={require('../../assets/images/Banner3.png')} alt="Banner3" />
        </BannerContainer>
      </Carousel>
      <Content style={{ padding: '0 4px', flex: 1, flexDirection: 'row' }}>
        <GridContent>
          <AdsContainer>
            <Ads src={require('../../assets/images/AdsBanner1.png')} alt="Ads1" />
          </AdsContainer>
          <Guess data={data} error={error} />
        </GridContent>
      </Content>
    </Layout>
  );
};

export default Home;
