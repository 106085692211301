import React from 'react';
import { Layout } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  Error,
  ErrorContainer,
  RefreshButton
} from './styles';

const NotFound: React.FC = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push('/');
  };

  return (
    <Layout>
      <ErrorContainer>
        <Error src={require('../../assets/images/NotFound.png')} alt="Error Networking" />
        <RefreshButton onClick={handleClick}>retornar a página inicial</RefreshButton>
      </ErrorContainer>
    </Layout>
  );
};

export default NotFound;
