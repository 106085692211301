import {
  TeamLegend,
  VersusContainer,
  Versus,
  TeamContainer,
  HomeInfo,
  StyledHomeOutlined,
  AwayInfo,
  TeamImage,
  ResultScore,
} from './styles';

import { Team } from './model';

export const CheckResult: React.FC<{ dataTeam: Team }> = ({ dataTeam }) => {
  if (dataTeam.partida_status == 'finalizado') {
    return (
      <TeamContainer>
        <HomeInfo>
          <ResultScore>{dataTeam.placar_mandante}</ResultScore>
          <TeamImage src={dataTeam.time_mandante_escudo} />
          <TeamLegend>{dataTeam.time_mandante_nome}</TeamLegend>
          <StyledHomeOutlined />
        </HomeInfo>
        <VersusContainer Margin={18} MarginAux={false}>
          <Versus>x</Versus>
        </VersusContainer>
        <AwayInfo>
          <ResultScore>{dataTeam.placar_visitante}</ResultScore>
          <TeamImage src={dataTeam.time_visitante_escudo} />
          <TeamLegend>{dataTeam.time_visitante_nome}</TeamLegend>
        </AwayInfo>
      </TeamContainer>
    )
  } else if (dataTeam.partida_status == 'agendado' || dataTeam.partida_status == 'adiado') {
    return (
      <TeamContainer>
        <HomeInfo>
          <TeamImage src={dataTeam.time_mandante_escudo} />
          <TeamLegend>{dataTeam.time_mandante_nome}</TeamLegend>
          <StyledHomeOutlined />
        </HomeInfo>
        <VersusContainer Margin={18} MarginAux={true}>
          <Versus>x</Versus>
        </VersusContainer>
        <AwayInfo>
          <TeamImage src={dataTeam.time_visitante_escudo} />
          <TeamLegend>{dataTeam.time_visitante_nome}</TeamLegend>
        </AwayInfo>
      </TeamContainer>
    )
  }
  else {
    return null;
  }
}
