import {
  Legend,
  ResultImage,
  GuessTitle
} from './styles';

import { Team } from './model';

export const CheckGuess: React.FC<{ dataTeam: Team }> = ({ dataTeam }) => {
  if (dataTeam.palpite === dataTeam.time_mandante_nome) {
    return (
      <div style={{ marginTop: 8 }}>
        <ResultImage src={dataTeam.time_mandante_escudo}></ResultImage>
        <Legend>{dataTeam.time_mandante_nome}</Legend>
      </div>
    );
  } else if (dataTeam.palpite === dataTeam.time_visitante_nome) {
    return (
      <div style={{ marginTop: 8 }}>
        <ResultImage src={dataTeam.time_visitante_escudo}></ResultImage>
        <Legend>{dataTeam.time_visitante_nome}</Legend>
      </div>
    );
  } else {
    return null;
  }
};

export const CheckStatus: React.FC<{ dataTeam: Team }> = ({ dataTeam }) => {
  if (dataTeam.palpite_status == 'pending') {
    return (
      <GuessTitle status="pending">PALPITE DE VENCEDOR</GuessTitle>
    );
  } else if (dataTeam.palpite_status == 'correct') {
    return (
      <GuessTitle status="correct">CORRETO</GuessTitle>
    );
  } else if (dataTeam.palpite_status == 'incorrect') {
    return (
      <GuessTitle status="incorrect">INCORRETO</GuessTitle>
    )
  } else {
    return null;
  }
};
