import styled from 'styled-components';
import { ContactsFilled } from '@ant-design/icons';

export const Container = styled.div`
  background: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 400px);
  width: 100%;
`;

export const Legend = styled.div`
  font-size: 18px;
  font-family: 'Calibri', sans-serif;
  font-weight: 600;
  color: #1c1c1c;
`;

export const LegendEmail = styled.div`
  margin-top: 8px;
  font-size: 18px;
  font-family: 'Calibri', sans-serif;
  font-weight: 400;
  color: #1c1c1c;
  margin-bottom: 14px;
  @media (max-width: 992px) {
    font-size: 16px;
    margin-top: 4px;
  }
  @media (max-width: 576px) {
    font-size: 14px;
    margin-top: 4px;
  }
`;

export const Description = styled.div`
  font-size: 18px;
  font-family: 'Calibri', sans-serif;
  font-weight: 400;
  text-align: justify;
  color: #1c1c1c;
  margin-top: 4px;
  @media (max-width: 992px) {
    font-size: 16px;
  }
  @media (max-width: 576px) {
    font-size: 14px;
    margin-top: 2px;
  }
`;

export const BannerContainer = styled.div<{ bgColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  margin-top: 4px;
  background: ${({ bgColor }) => bgColor};
  @media (max-width: 900px) {
    height: auto;
    padding: 8px;
  }
`;

export const Banner = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  margin: auto;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-top: 16px;
  @media (max-width: 992px) {
    margin-top: 16px;
    width: 90%;
  }
  @media (max-width: 576px) {
    margin-top: 8px;
    width: 90%;
  }
`;
