import styled, { keyframes } from 'styled-components';

export const GridContent = styled.div`
  background: #FFF;
  display: flex;
  min-height: calc(100vh - 80px);
  width: 100%;
`;

export const BannerContainer = styled.div<{ bgColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  margin-top: 4px;
  background: ${({ bgColor }) => bgColor};

  @media (max-width: 900px) {
    height: auto;
    padding: 8px;
  }
`;

export const Banner = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  margin: auto;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

export const AdsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px; 
  height: 600px;
  margin-top: 85px;
  position: sticky;
  top: 200px;

  @media (max-width: 768px) {
    width: 180px; 
    height: 350px;
  }
  @media (max-width: 576px) {
    display: none;
  }
`;

export const Ads = styled.img`
  width: 100%;
  height: 100%;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Error = styled.img`
  width: 500px;
  height: 200px;
  @media (max-width: 768px) {
    width: 350px;
    height: 150px;
  }
`;

export const RefreshButton = styled.button`
  margin-top: 24px;
  padding: 10px 20px;
  font-size: 16px;
  font-family: 'Calibri', sans-serif;
  color: #fff;
  background-color: #004aad;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #007bff;
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoadingSpinner = styled.div`
  border: 8px solid #f3f3f3;
  border-top: 8px solid #004aad;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spin} 1s linear infinite;
`;
