import styled from 'styled-components';

export const ErrorContainer = styled.div`
  margin: 4px;
  background: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 600px;
`;

export const Error = styled.img`
  width: 500px;
  height: 200px;
  @media (max-width: 768px) {
    width: 350px;
    height: 150px;
  }
`;

export const RefreshButton = styled.button`
  margin-top: 24px;
  padding: 10px 20px;
  font-size: 16px;
  font-family: 'Calibri', sans-serif;
  color: #fff;
  background-color: #004aad;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #007bff;
  }
`;
