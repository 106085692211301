import React, { useState } from 'react';
import { List } from 'antd';
import {
  RoundContainer,
  RoundTitle,
  RoundLegend,
  GuessContainer,
  MatchContainer,
  MatchInfo,
  MatchColumnContainer,
  MatchColumn,
  MatchLegend,
  MatchStatus,
  DescriptionContainer,
  Description
} from './styles';

import { DataItem } from './model';

import { CheckResult } from './checkResult';
import { CheckGuess, CheckStatus } from './checkGuessAndStatus';

const GuessList: React.FC<DataItem> = ({ palpites, rodada, status }) => {
  const [data, setData] = useState(palpites);
  return (
    <div>
      <List
        dataSource={data}
        header={
          <RoundContainer>
            <RoundTitle>Palpites do Brasileirão Série A</RoundTitle>
            <RoundLegend>Rodada {rodada} de 38 | Status: {status}</RoundLegend>
          </RoundContainer>
        }
        renderItem={(item) => (
          <List.Item>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <CheckResult dataTeam={item} />
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <MatchContainer>
                  <MatchInfo>INFORMAÇÕES DA PARTIDA</MatchInfo>
                  <MatchColumnContainer>
                    <MatchColumn>
                      <MatchLegend>Data: {item.partida_data}</MatchLegend>
                      <MatchLegend>Horário: {item.partida_hora}</MatchLegend>
                    </MatchColumn>
                    <MatchColumn>
                      <MatchLegend>Mandante: {item.time_mandante_nome}</MatchLegend>
                      <MatchLegend>Estádio: {item.estadio_nome}</MatchLegend>
                    </MatchColumn>
                    <MatchColumn>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <MatchLegend>Status da Partida:</MatchLegend>
                        <MatchStatus status={item.partida_status}>{item.partida_status}</MatchStatus>
                      </div>
                    </MatchColumn>
                    <DescriptionContainer>
                      <Description>Para a partida {item.time_mandante_nome} x {item.time_visitante_nome} da rodada {item.rodada} do Brasileirão Serie A - 2024 que será realizada {item.partida_data}, o palpite de vencedor é o {item.palpite}.</Description>
                    </DescriptionContainer>
                  </MatchColumnContainer>
                </MatchContainer>
                <GuessContainer>
                  <CheckStatus dataTeam={item} />
                  <CheckGuess dataTeam={item} />
                </GuessContainer>
              </div>
            </div>
          </List.Item>
        )}
      />
    </div>
  );
};

export default GuessList;
